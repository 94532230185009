/* eslint-disable */
import * as _m0 from "protobufjs/minimal";

export const protobufPackage = "";

export enum UserType {
  UNKNOWN_TYPE = 0,
  CONSUMER = 1,
  AGENT = 2,
  EMPLOYEE = 3,
  LEADSELLER = 4,
  AUTHORITY = 5,
  AGENCY = 6,
  CARRIER = 7,
  PUBLISHER = 8,
  UNRECOGNIZED = -1,
}

export function userTypeFromJSON(object: any): UserType {
  switch (object) {
    case 0:
    case "UNKNOWN_TYPE":
      return UserType.UNKNOWN_TYPE;
    case 1:
    case "CONSUMER":
      return UserType.CONSUMER;
    case 2:
    case "AGENT":
      return UserType.AGENT;
    case 3:
    case "EMPLOYEE":
      return UserType.EMPLOYEE;
    case 4:
    case "LEADSELLER":
      return UserType.LEADSELLER;
    case 5:
    case "AUTHORITY":
      return UserType.AUTHORITY;
    case 6:
    case "AGENCY":
      return UserType.AGENCY;
    case 7:
    case "CARRIER":
      return UserType.CARRIER;
    case 8:
    case "PUBLISHER":
      return UserType.PUBLISHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return UserType.UNRECOGNIZED;
  }
}

export function userTypeToJSON(object: UserType): string {
  switch (object) {
    case UserType.UNKNOWN_TYPE:
      return "UNKNOWN_TYPE";
    case UserType.CONSUMER:
      return "CONSUMER";
    case UserType.AGENT:
      return "AGENT";
    case UserType.EMPLOYEE:
      return "EMPLOYEE";
    case UserType.LEADSELLER:
      return "LEADSELLER";
    case UserType.AUTHORITY:
      return "AUTHORITY";
    case UserType.AGENCY:
      return "AGENCY";
    case UserType.CARRIER:
      return "CARRIER";
    case UserType.PUBLISHER:
      return "PUBLISHER";
    case UserType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum UserRole {
  R_UNKNOWN = 0,
  agent = 1,
  admin = 2,
  dialerAdmin = 3,
  UNRECOGNIZED = -1,
}

export function userRoleFromJSON(object: any): UserRole {
  switch (object) {
    case 0:
    case "R_UNKNOWN":
      return UserRole.R_UNKNOWN;
    case 1:
    case "agent":
      return UserRole.agent;
    case 2:
    case "admin":
      return UserRole.admin;
    case 3:
    case "dialerAdmin":
      return UserRole.dialerAdmin;
    case -1:
    case "UNRECOGNIZED":
    default:
      return UserRole.UNRECOGNIZED;
  }
}

export function userRoleToJSON(object: UserRole): string {
  switch (object) {
    case UserRole.R_UNKNOWN:
      return "R_UNKNOWN";
    case UserRole.agent:
      return "agent";
    case UserRole.admin:
      return "admin";
    case UserRole.dialerAdmin:
      return "dialerAdmin";
    case UserRole.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum DialerRole {
  ROLE_UNKNOWN = 0,
  MARKETING_AGENT = 1,
  SALES_AGENT = 2,
  TEAM_LEAD = 3,
  UNRECOGNIZED = -1,
}

export function dialerRoleFromJSON(object: any): DialerRole {
  switch (object) {
    case 0:
    case "ROLE_UNKNOWN":
      return DialerRole.ROLE_UNKNOWN;
    case 1:
    case "MARKETING_AGENT":
      return DialerRole.MARKETING_AGENT;
    case 2:
    case "SALES_AGENT":
      return DialerRole.SALES_AGENT;
    case 3:
    case "TEAM_LEAD":
      return DialerRole.TEAM_LEAD;
    case -1:
    case "UNRECOGNIZED":
    default:
      return DialerRole.UNRECOGNIZED;
  }
}

export function dialerRoleToJSON(object: DialerRole): string {
  switch (object) {
    case DialerRole.ROLE_UNKNOWN:
      return "ROLE_UNKNOWN";
    case DialerRole.MARKETING_AGENT:
      return "MARKETING_AGENT";
    case DialerRole.SALES_AGENT:
      return "SALES_AGENT";
    case DialerRole.TEAM_LEAD:
      return "TEAM_LEAD";
    case DialerRole.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface UserContainer {
  id?: string | undefined;
  type?: UserType | undefined;
}

function createBaseUserContainer(): UserContainer {
  return { id: undefined, type: undefined };
}

export const UserContainer = {
  encode(message: UserContainer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(10).string(message.id);
    }
    if (message.type !== undefined) {
      writer.uint32(16).int32(message.type);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserContainer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserContainer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserContainer {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      type: isSet(object.type) ? userTypeFromJSON(object.type) : undefined,
    };
  },

  toJSON(message: UserContainer): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.type !== undefined && (obj.type = message.type !== undefined ? userTypeToJSON(message.type) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<UserContainer>, I>>(base?: I): UserContainer {
    return UserContainer.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserContainer>, I>>(object: I): UserContainer {
    const message = createBaseUserContainer();
    message.id = object.id ?? undefined;
    message.type = object.type ?? undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
